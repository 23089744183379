import { CommonLink } from 'components/common/Forms/Button';
import NavbarDropdown from 'components/NavbarDropdown';
import { useAuth } from 'context/AuthContext';
import { useGlobalAttributes } from 'context/GlobalAttributesContext';
import { useSite } from 'context/SiteContext';
import { ReactElement } from 'react';
import { FaBell, FaExclamationTriangle } from 'react-icons/fa';
import { INavbarDropdown } from 'types/common';
import {
  ExpireIn,
  NavActions,
  NavbarLeft,
  NavbarMiddle,
  NavbarRight,
  NavbarWrapper,
  SangixLogoWrapper,
  StyledSangixLogo,
} from './Navbar.styled';
import { isBefore, parseDate, parseIsoDate } from '../../utils/dateUtil';
import { differenceInDays } from 'date-fns';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { LOGIN_ROUTE } from '../../routes/AppRoutes';
import { SiteData } from '../../types/sites';
import { Link } from 'react-router-dom';

export interface NavbarProps {
  dropdowns?: INavbarDropdown[];
  /**
   * If `false` is passed, no logo is shown.
   * If `false` is passed, logo is shown.
   * Defaults to `true`.
   */
  logo?: boolean;
  /**
   * If `string` is passed, it will be used as title.
   * If `false` is passed, no title is shown.
   * If `true` is passed, active site name is used if present.
   * Defaults to `true`.
   */
  title?: string | boolean;
  patientLayout?: boolean;
  releaseNote?: {
    to: string;
    key: 'PATIENT' | 'STAFF';
  };
  loginButtonForNoUser?: boolean;
}

export const Navbar = ({
  dropdowns,
  logo = true,
  title = true,
  releaseNote,
  patientLayout,
  loginButtonForNoUser,
}: NavbarProps): ReactElement => {
  const { t } = useCommonTranslation();
  const { attributes } = useGlobalAttributes();
  const { sangixUser } = useAuth();
  const { activeSite: optionalActiveSite } = useSite();
  // Active site can be undefined if user is not logged in
  // TODO: Handle this case in a better way
  const activeSite = optionalActiveSite as SiteData | undefined;
  const expireDays = activeSite
    ? differenceInDays(parseDate(activeSite.expire_date), new Date())
    : Number.MAX_SAFE_INTEGER;

  const releaseNoteAttr = sangixUser?.release_note;
  const userReleaseNoteDate = releaseNoteAttr && parseIsoDate(releaseNoteAttr);
  const globalReleaseNotAttr = attributes?.find(
    (attr) => attr.key === releaseNote?.key,
  )?.value_str;
  const globalReleaseNoteDate =
    globalReleaseNotAttr && parseIsoDate(globalReleaseNotAttr);

  const releaseNoteHasUpdated =
    userReleaseNoteDate &&
    globalReleaseNoteDate &&
    isBefore(userReleaseNoteDate, globalReleaseNoteDate);

  return (
    <NavbarWrapper patientLayout={patientLayout}>
      <NavbarLeft>
        {logo && (
          <SangixLogoWrapper>
            <Link to={'/'}>
              <StyledSangixLogo />
            </Link>
          </SangixLogoWrapper>
        )}
        {!patientLayout && expireDays <= 30 && (
          <ExpireIn>
            <FaExclamationTriangle />
            {t('licence-expire-in', { days: expireDays })}
          </ExpireIn>
        )}
      </NavbarLeft>
      <NavbarMiddle>
        {title === true && activeSite?.name}
        {typeof title === 'string' && title}
      </NavbarMiddle>
      <NavbarRight>
        <NavActions>
          {dropdowns?.map(
            (dropdown, index) =>
              Boolean(dropdown.links.length) && (
                <NavbarDropdown
                  dropdown={dropdown}
                  key={dropdown.label + index}
                />
              ),
          )}
          {loginButtonForNoUser && !sangixUser && (
            <CommonLink
              variant="secondary"
              to={LOGIN_ROUTE}
              style={{
                marginLeft: 'var(--s2)',
              }}
            >
              {t('login')}
            </CommonLink>
          )}
          {Boolean(releaseNote) && (
            <CommonLink
              size="auto"
              variant="danger"
              disabled={!releaseNoteHasUpdated}
              iconOnly
              to={releaseNote?.to as string}
              style={{
                marginLeft: 'var(--s2)',
              }}
            >
              <FaBell />
            </CommonLink>
          )}
        </NavActions>
      </NavbarRight>
    </NavbarWrapper>
  );
};
