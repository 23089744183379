import React, { useCallback, useContext, useEffect, useState } from 'react';
import i18n, { DEFAULT_LANGUAGE, Language } from '../i18n';

interface II18NContext {
  language: Language;
  setLanguage: (language: Language) => Promise<void>;
}

const defaultContext: II18NContext = {
  language: DEFAULT_LANGUAGE,
  setLanguage: async () => console.warn('I18N Context not ready'),
};

export const I18NContext = React.createContext<II18NContext>(defaultContext);
I18NContext.displayName = 'I18N Context'; // Only for debugging

export const useI18N = () => useContext(I18NContext);

export const I18NProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState<Language>(i18n.language as Language);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setLanguage(lng as Language);
    });
  }, []);

  const handleSetLanguage: (language: Language) => Promise<void> = useCallback(
    async (language: Language) => {
      await i18n.changeLanguage(language);
    },
    [],
  );

  return (
    <I18NContext.Provider
      value={{
        language,
        setLanguage: handleSetLanguage,
      }}
    >
      {children}
    </I18NContext.Provider>
  );
};
