import { Navbar, NavbarProps } from 'components/Navbar/NavBar';
import {
  Bars,
  MobileNavItem,
  MobileNavList,
  MobileNavMenuBox,
  NavbarLeft,
  NavbarMiddle,
  NavbarRight,
  NavbarWrapper,
  SangixLogoWrapper,
  StyledSangixLogo,
} from 'components/Navbar/Navbar.styled';
import {
  PatientNavbarDropdowns,
  PatientUnAuthNavbarDropdowns,
} from 'configs/RoutesConfig';
import { useAuth } from 'context/AuthContext';
import { useRole } from 'context/RoleContext';
import { useThemeMedia } from 'hooks/useThemeMedia';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { mq } from 'styles/sizes';

import { LOGIN_ROUTE, patientURL } from '../../routes/AppRoutes';
import { usePatientSites } from './PatientHooks';
import { languagesOptions } from '../../i18n';
import { FaCheck } from 'react-icons/fa';
import { PatientMobileNavbarLanguageLoadingIcon } from './PatientNavBar.styled';
import { useChangeLanguage } from './PatientNavBarHooks';

type MenuBtnProps = {
  open?: boolean;
  onClick: () => unknown;
};
const MenuBtn = (props: MenuBtnProps) => {
  return <Bars {...props} id="menu-btn" />;
};

type PatientNavBarProps = Pick<NavbarProps, 'title'> & {
  showLoginButtonForNoUser?: boolean;
};

const PatientNavBarDesktop = ({
  title,
  showLoginButtonForNoUser,
}: PatientNavBarProps) => {
  const { sangixUser, logout } = useAuth();
  const { hasAnyOfRoles } = useRole();
  const { t } = useCommonTranslation();
  const changeLanguageReturn = useChangeLanguage();

  const navbarDropdowns = useMemo(
    () =>
      !sangixUser
        ? PatientUnAuthNavbarDropdowns({ t, ...changeLanguageReturn })
        : PatientNavbarDropdowns({
            sangixUser,
            logout,
            hasAnyOfRoles,
            t,
            ...changeLanguageReturn,
          }),
    [hasAnyOfRoles, logout, t, sangixUser, changeLanguageReturn],
  );

  return sangixUser ? (
    <Navbar
      logo={true}
      title={title}
      dropdowns={navbarDropdowns}
      patientLayout
      releaseNote={{
        key: 'PATIENT',
        to: `${patientURL}/release-notes`,
      }}
    />
  ) : (
    <Navbar
      logo={true}
      title={title}
      dropdowns={navbarDropdowns}
      patientLayout
      loginButtonForNoUser={showLoginButtonForNoUser}
    />
  );
};

const PatientMobileLanguageNavList = ({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) => {
  const { language, inProgressChangingLanguageTo, setLanguage } =
    useChangeLanguage();

  return (
    <MobileNavList
      style={{
        borderTop: `1px solid var(--textprimary)`,
        marginTop: 'var(--s2)',
        paddingTop: 'var(--s2)',
      }}
    >
      {languagesOptions.map(({ value, label }) => (
        <MobileNavItem
          key={value}
          onClick={async () => {
            if (inProgressChangingLanguageTo) return;
            if (value !== language) {
              await setLanguage(value);
            }
            setOpen(false);
          }}
        >
          {label}
          {value === language && (
            <>
              &nbsp;
              <FaCheck />
            </>
          )}
          {value === inProgressChangingLanguageTo && (
            <>
              &nbsp;
              <PatientMobileNavbarLanguageLoadingIcon />
            </>
          )}
        </MobileNavItem>
      ))}
    </MobileNavList>
  );
};

const PatientNavBarMobileGuest = ({
  title,
  showLoginButtonForNoUser,
}: PatientNavBarProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useCommonTranslation();

  return (
    <>
      <NavbarWrapper patientLayout>
        <NavbarLeft>
          <SangixLogoWrapper>
            <Link to={'/'}>
              <StyledSangixLogo />
            </Link>
          </SangixLogoWrapper>
        </NavbarLeft>
        <NavbarMiddle>{typeof title === 'string' && title}</NavbarMiddle>
        <NavbarRight>
          {!open && <MenuBtn open={false} onClick={() => setOpen(!open)} />}
        </NavbarRight>
      </NavbarWrapper>
      {open && <MenuBtn open onClick={() => setOpen(!open)} />}
      <MobileNavMenuBox open={open}>
        <MobileNavList onClick={() => setOpen(false)}>
          {showLoginButtonForNoUser && (
            <MobileNavItem>
              <Link to={LOGIN_ROUTE}>{t('login')}</Link>
            </MobileNavItem>
          )}
          <MobileNavItem>
            <Link to={`/support`}>{t('support')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`/gdpr`}>{t('personal-data')}</Link>
          </MobileNavItem>
        </MobileNavList>
        <PatientMobileLanguageNavList setOpen={setOpen} />
      </MobileNavMenuBox>
    </>
  );
};

const PatientNavBarMobile = ({
  title,
  showLoginButtonForNoUser,
}: PatientNavBarProps) => {
  const { t } = useCommonTranslation();
  const [open, setOpen] = useState(false);
  const { sangixUser, logout } = useAuth();
  const { activeSite } = usePatientSites();

  if (!sangixUser)
    return (
      <PatientNavBarMobileGuest
        title={title}
        showLoginButtonForNoUser={showLoginButtonForNoUser}
      />
    );
  return (
    <>
      <NavbarWrapper patientLayout>
        <NavbarLeft>
          <SangixLogoWrapper>
            <Link to={'/'}>
              <StyledSangixLogo />
            </Link>
          </SangixLogoWrapper>
        </NavbarLeft>
        <NavbarMiddle>
          {title === true && activeSite?.name}
          {typeof title === 'string' && title}
        </NavbarMiddle>
        <NavbarRight>
          {!open && <MenuBtn open={false} onClick={() => setOpen(!open)} />}
        </NavbarRight>
      </NavbarWrapper>
      {open && <MenuBtn open onClick={() => setOpen(!open)} />}
      <MobileNavMenuBox open={open}>
        <MobileNavList onClick={() => setOpen(false)}>
          <MobileNavItem>
            <Link to={`${patientURL}/release-notes`}>{t('release-notes')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`${patientURL}/profile`}>{t('my-profile')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`${patientURL}/change-password`}>{t('password')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`/support`}>{t('support')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`/gdpr`}>{t('personal-data')}</Link>
          </MobileNavItem>
          <MobileNavItem onClick={logout}>{t('logout')}</MobileNavItem>
        </MobileNavList>
        <PatientMobileLanguageNavList setOpen={setOpen} />
      </MobileNavMenuBox>
    </>
  );
};

export const PatientNavBar = (props: PatientNavBarProps) => {
  const isDesktop = useThemeMedia(mq.sm);
  if (isDesktop) return <PatientNavBarDesktop {...props} />;
  return <PatientNavBarMobile {...props} />;
};
