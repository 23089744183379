import notfound404 from 'images/404/404.svg';
import doctor404 from 'images/404/doctor.svg';
import error500 from 'images/500/500.svg';
import doctor500 from 'images/500/doctor.svg';
import circle500 from 'images/500/greenCircle.svg';
import boySvg from 'images/patient-portal/boy.svg';
import cloudSvg from 'images/patient-portal/cloud.svg';
import doctorHeartSvg from 'images/patient-portal/doctor-heart.svg';
import doctorTreeSvg from 'images/patient-portal/doctor-tree.svg';
import doctorSvg from 'images/patient-portal/doctor.svg';
import styled, { css } from 'styled-components';
import { cssIf } from 'styles/partials/cssIf';
import { listItemMarginX } from 'styles/partials/listItemMargin';
import { h3, h4 } from 'styles/Typography';
import { ClientRect } from 'types/common';
import { toRem } from 'utils/helpers';
import { CommonButton } from './Forms/Button';

const PATIENT_LAYOUT_MIN_WIDTH_PX = 330;
const PATIENT_AUTHORIZED_MAX_WIDTH_REM = 45;

export const LayoutWrapper = styled.div<{ fixedHeight?: boolean }>`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  background: var(--greenmuted);
  padding: calc(${toRem(80)}rem + var(--s3)) var(--s3) var(--s4)
    calc(${toRem(160)}rem + var(--s3));
  ${cssIf('fixedHeight')`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  `}
`;

export const Card = styled.div<{
  readonly fade?: boolean;
  readonly mt?: string;
  readonly mr?: string;
  readonly mb?: string;
  readonly ml?: string;
  readonly fillHeight?: boolean;
  readonly scroll?: boolean;
  readonly flex?: boolean;
  readonly minHeight?: string;
}>`
  position: relative;
  background-color: var(--white);
  padding: var(--s3);
  box-shadow: var(--shadow-3);
  border-radius: var(--s3);
  margin: ${({ mt }) => mt || 0} ${({ mr }) => mr || 'var(--s3)'}
    ${({ mb }) => mb || 0} ${({ ml }) => ml || 'var(--s3)'};
  ${({ minHeight = 'auto' }) => `min-height: ${minHeight};`}

  ${({ fade }) =>
    fade &&
    css`
      max-height: calc(100vh - var(--s6) * 3 - var(--s3));
      display: flex;
      flex-direction: column;
    `}
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      flex-direction: column;
    `}
  ${({ fillHeight }) =>
    fillHeight &&
    css`
      min-height: calc(100vh - ${toRem(80)}rem - ${toRem(64)}rem);
    `}
  ${cssIf('scroll')`
    overflow-y: auto;
    max-height: 100%;
  `}
`;

export const CardDividerHeaderSection = styled.div`
  display: flex;
  align-items: center;
  padding: var(--s2) 0 var(--s3) 0;
  border-bottom: ${toRem(2)}rem solid var(--greenaccent);
  position: relative;
`;

export const CardHeader = styled.div<{
  readonly oneLine?: boolean;
}>`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--s3);

  & > svg {
    margin-bottom: var(--s3);
    line-height: 0;
    height: ${toRem(100)}rem;
    width: 100%;
    color: var(--greenaccent);
  }

  ${({ oneLine }) =>
    oneLine &&
    css`
      flex-direction: row;
      justify-content: center;

      svg {
        width: auto;
        margin-bottom: 0;
        height: ${toRem(24)}rem;
        margin-right: var(--s2);
      }
    `}
`;
export const FadeContent = styled.div`
  overflow-y: auto;
  flex: 1;
  padding: var(--s3);
`;
export const CardCloseButton = styled(CommonButton)`
  position: absolute;
  top: var(--s3);
  right: var(--s3);
  font-size: var(--h3);
`;
export const FadeWrapper = styled.div`
  flex: 1;
  overflow: hidden !important;
  position: relative;
  display: flex;
  margin: 0 calc(-1 * var(--s3));
  border-radius: var(--s3);

  &::before,
  &::after {
    content: '';
    height: var(--s3);
    width: 100%;
    position: absolute;
    display: block;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      180deg,
      var(--white) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
    transform: rotate(180deg);
  }
`;

type CardFooterProps = { spaceBetween?: boolean };
export const CardFooter = styled.div<CardFooterProps>`
  padding: var(--s3) 0 0;
  display: flex;
  justify-content: flex-end;

  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      ${listItemMarginX('var(--s3)')}
      & > * {
        ${listItemMarginX('var(--s3)')}
      }
    `}
  & > a {
    min-width: ${toRem(160)}rem;
  }
`;
export const PatientLayoutWrapper = styled.div<{
  readonly colorScheme?: string;
}>`
  background: ${({ colorScheme }) =>
    colorScheme ? `var(${colorScheme})` : 'var(--greentercial)'};
  position: relative;
  min-height: 100vh;
  min-width: ${PATIENT_LAYOUT_MIN_WIDTH_PX}px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PatientInner = styled.div<{
  readonly authorized?: boolean;
  readonly maxWidth?: number;
}>`
  min-width: ${PATIENT_LAYOUT_MIN_WIDTH_PX}px; // in pixels because on smaller screens it should not take less space
  z-index: 1;
  margin: ${toRem(96)}rem 0;
  flex: 1;
  max-width: ${({ authorized, maxWidth }) =>
    authorized ? PATIENT_AUTHORIZED_MAX_WIDTH_REM : maxWidth || 75}rem;
`;
export const UnAuthGraphics = styled.div<{
  readonly contentRect: ClientRect;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${boySvg}), url(${doctorSvg}), url(${cloudSvg});
  background-repeat: no-repeat;
  background-position: calc(
        ${({ contentRect }) => contentRect.left - contentRect.width}px
      )
      ${toRem(240)}rem,
    calc(
        100% -
          ${({ contentRect }) => `${contentRect.left - contentRect.width}px`}
      )
      ${toRem(244)}rem,
    center ${toRem(72)}rem;
  background-size: ${toRem(390)}rem ${toRem(808)}rem,
    ${toRem(542)}rem ${toRem(756)}rem, ${toRem(1328)}rem ${toRem(877)}rem;
`;
export const PatientGraphics = styled.div<{
  readonly contentRect: ClientRect;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${doctorHeartSvg}), url(${doctorTreeSvg}), url(${cloudSvg});
  background-repeat: no-repeat;
  /* TODO: Background Positions setted to pixels to ensure that both vectors are stickied with the center card in any screen */
  background-position: calc(
        ${({ contentRect }) => contentRect.x + contentRect.width}px
      )
      ${toRem(177)}rem,
    calc(
        ${({ contentRect }) => `100% - ${contentRect.x + contentRect.width}px`}
      )
      ${toRem(244)}rem,
    center ${toRem(72)}rem;
  background-size: ${toRem(390)}rem ${toRem(808)}rem,
    ${toRem(542)}rem ${toRem(756)}rem, ${toRem(1328)}rem ${toRem(877)}rem;
`;
export const Graphics500 = styled.div<{
  readonly contentRect: ClientRect;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${error500}), url(${doctor500}), url(${circle500});
  background-repeat: no-repeat;
  /* TODO: Background Positions setted to pixels to ensure that both vectors are stickied with the center card in any screen */
  background-position: calc(
        ${({ contentRect }) => contentRect.x / 2 + contentRect.width}px
      )
      53%,
    calc(
        ${({ contentRect }) =>
          `100% - ${contentRect.x + contentRect.width}px + 5%`}
      )
      53%,
    40% 50%;
  background-size: ${toRem(918)}rem ${toRem(346)}rem,
    ${toRem(481)}rem ${toRem(430)}rem, ${toRem(933)}rem ${toRem(706)}rem;
`;

export const Graphics404 = styled.div<{
  readonly contentRect: ClientRect;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${notfound404}), url(${doctor404}), url(${circle500});
  background-repeat: no-repeat;
  /* TODO: Background Positions setted to pixels to ensure that both vectors are stickied with the center card in any screen */
  background-position: calc(
        ${({ contentRect }) => contentRect.x / 1.63 + contentRect.width}px
      )
      33%,
    calc(
        ${({ contentRect }) =>
          `100% - ${contentRect.x + contentRect.width}px + 143px`}
      )
      53%,
    40% 50%;
  background-size: ${toRem(806)}rem ${toRem(470)}rem,
    ${toRem(481)}rem ${toRem(430)}rem, ${toRem(933)}rem ${toRem(706)}rem;
`;

export const InfoMessageWrapper = styled.div<{
  readonly type?:
    | 'error'
    | 'error-alt'
    | 'info'
    | 'info-alt'
    | 'success'
    | 'success-alt'
    | 'warning'
    | 'warning-alt';
}>`
  display: flex;
  padding: var(--s2);
  border-radius: var(--s3);
  box-shadow: var(--shadow-3);

  .icon {
    margin-right: var(--s2);
    line-height: 0;
  }

  .text {
    font-size: ${toRem(14)}rem;
  }

  ${({ type }) =>
    type === 'error'
      ? css`
          background-color: var(--rednegative);
          color: var(--white);
        `
      : type === 'error-alt'
      ? css`
          color: var(--rednegative);
          background-color: var(--white);
          border: ${toRem(2)}rem solid var(--rednegative);
        `
      : type === 'info'
      ? css`
          background-color: var(--blueprimary);
          color: var(--white);
        `
      : type === 'info-alt'
      ? css`
          background-color: var(--blueprimary);
          color: var(--white);
          border: ${toRem(2)}rem solid var(--blueprimary);
        `
      : type === 'success'
      ? css`
          background-color: var(--greenaccent);
          color: var(--white);
        `
      : type === 'success-alt'
      ? css`
          background-color: var(--greenaccent);
          color: var(--white);
          border: ${toRem(2)}rem solid var(--greenaccent);
        `
      : type === 'warning'
      ? css`
          background-color: var(--yellowaction);
          color: var(--white);
        `
      : css`
          background-color: var(--yellowaction);
          color: var(--white);
          border: ${toRem(2)}rem solid var(--yellowaction);
        `}
`;
export const CardTitle = styled.h3`
  ${h3};
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  margin: var(--s3) 0;
`;

export const CardDesc = styled.p`
  ${h4};
  text-align: center;
`;
export const CardDescWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--s2);

  button {
    margin-left: var(--s2);
  }
`;
export const CardSubtitle = styled.h4`
  text-transform: uppercase;
  font-size: var(--h4);
  margin-block-start: 0;
  margin-block-end: var(--s3);
`;

export const CardNote = styled.div`
  font-size: var(--bodysm);
  margin-block-start: 0;
  margin-block-end: var(--s3);
`;
