import { useAuth } from 'context/AuthContext';
import { useCallback, useState } from 'react';

import { useI18N } from '../../context/I18NContext';
import { Language } from '../../i18n';
import { getUserProfile, updateUserProfile } from '../../services/userProfile';
import { errorToast } from '../../utils/toast';

export interface UseChangeLanguageReturn {
  language: Language;
  inProgressChangingLanguageTo: Language | undefined;
  setLanguage: (language: Language) => Promise<void>;
}

export const useChangeLanguage: () => UseChangeLanguageReturn = () => {
  const { sangixUser, updateAuthUser } = useAuth();
  const { language, setLanguage } = useI18N();
  const [inProgressChangingLanguageTo, setInProgressChangingLanguageTo] =
    useState<Language | undefined>(undefined);

  const handleSetLanguage = useCallback(
    async (language: Language) => {
      setInProgressChangingLanguageTo(language);
      try {
        if (sangixUser) {
          await updateUserProfile({ ...sangixUser, language });
          const { data: profileData } = await getUserProfile();
          updateAuthUser({
            sangixUser: profileData,
          });
        } else {
          await setLanguage(language);
        }
      } catch (err) {
        errorToast(err);
      } finally {
        setInProgressChangingLanguageTo(undefined);
      }
    },
    [sangixUser, updateAuthUser, setLanguage],
  );
  return {
    language,
    inProgressChangingLanguageTo,
    setLanguage: handleSetLanguage,
  };
};
