import { useThemeMedia } from 'hooks/useThemeMedia';
import { ReactElement, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { mq } from 'styles/sizes';
import { INavbarDropdown } from 'types/common';
import {
  DropdownBtn,
  DropdownItemIcon,
  DropdownLink,
  DropdownMenu,
  DropdownWrapper,
  IconWrapper,
  ListItem,
} from './NavbarDropdown.styled';

interface Props {
  dropdown: INavbarDropdown;
}

export default function NavbarDropdown({
  dropdown: {
    autoWidth,
    accentColor,
    secondaryColor,
    icon,
    label,
    labelAlt,
    links,
  },
}: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const isDesktop = useThemeMedia(mq.sm);
  useClickAway(dropdownRef, () => setIsOpen(false));

  return (
    <DropdownWrapper ref={dropdownRef} autoWidth={autoWidth}>
      <DropdownBtn
        type="button"
        isActive={isOpen}
        accentColor={accentColor}
        secondaryColor={secondaryColor}
        hasIcon={!!icon}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen((prev) => !prev);
        }}
        autoWidth={autoWidth}
      >
        {isOpen ? labelAlt || label : isDesktop ? label : ''}
        {icon && <IconWrapper isActive={isOpen}>{icon}</IconWrapper>}
      </DropdownBtn>
      {isOpen && (
        <DropdownMenu>
          {links.map((item) => (
            <ListItem key={label + '-' + item.label}>
              {typeof item.onClickHandler === 'string' ? (
                <DropdownLink to={item.onClickHandler}>
                  {Boolean(item.icon) && (
                    <DropdownItemIcon>{item.icon}</DropdownItemIcon>
                  )}
                  {item.label}
                </DropdownLink>
              ) : (
                <DropdownLink
                  as="button"
                  onClick={() => {
                    item.onClickHandler();
                    setIsOpen(false);
                  }}
                >
                  {Boolean(item.icon) && (
                    <DropdownItemIcon>{item.icon}</DropdownItemIcon>
                  )}
                  {item.label}
                </DropdownLink>
              )}
            </ListItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
}
