import React, { PropsWithChildren, useMemo } from 'react';
import { DefaultLayout } from '../../components/Layout/DefaultLayout';
import { useAuth } from '../../context/AuthContext';
import { useRole } from '../../context/RoleContext';
import { useSite } from '../../context/SiteContext';
import { SiteData } from '../../types/sites';
import {
  FaClipboardList,
  FaDoorOpen,
  FaHospitalSymbol,
  FaKey,
  FaUserCircle,
  FaUserCog,
} from 'react-icons/fa';
import {
  GET_STAFF_RELEASENOTE_ROLES,
  SUPERADMIN,
} from '../../configs/constants';
import { errorToast } from '../../utils/toast';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

type StaffLayoutProps = PropsWithChildren<{ fixedHeight?: boolean }>;

export const LIST_MAX_SCROLL_HEIGHT = 735;

export const StaffLayout: React.FunctionComponent<StaffLayoutProps> = ({
  children,
  fixedHeight,
}) => {
  const { t } = useCommonTranslation();
  const { sangixUser, logout } = useAuth();
  const { hasAnyOfRoles } = useRole();
  const { activeSite, setActiveSiteById, allSites } = useSite();

  const availableSites = useMemo(() => {
    if (hasAnyOfRoles(SUPERADMIN)) {
      return allSites
        ?.filter((site) => site.trust_id === activeSite.trust_id)
        .map((site) => ({
          site_id: site.id,
          site_short_name: site.short_name,
        }));
    }
    return sangixUser?.roles;
  }, [sangixUser, allSites, activeSite, hasAnyOfRoles]);

  const navbarDropdowns = useMemo(() => {
    return [
      {
        label: (activeSite as SiteData)?.short_name,
        labelAlt: t('sites'),
        icon: <FaHospitalSymbol />,
        accentColor: 'var(--blueprimary)',
        secondaryColor: 'var(--bluesecondary)',
        links:
          availableSites?.map((site) => ({
            label: site.site_short_name,
            onClickHandler: async () => {
              try {
                setActiveSiteById(site.site_id);
              } catch (err) {
                errorToast(err);
              }
            },
            icon: <FaHospitalSymbol />,
          })) || [],
      },
      {
        label: sangixUser
          ? sangixUser?.firstname + ' ' + sangixUser?.lastname
          : '',
        icon: <FaUserCircle />,
        accentColor: 'var(--greenaccent)',
        secondaryColor: 'var(--greentercial)',
        links: [
          ...(hasAnyOfRoles(SUPERADMIN)
            ? []
            : [
                {
                  label: t('my-profile'),
                  icon: <FaUserCog />,
                  onClickHandler: '/staff/profile',
                },
                {
                  label: t('password'),
                  icon: <FaKey />,
                  onClickHandler: '/staff/change-password',
                },
              ]),
          ...(hasAnyOfRoles(...GET_STAFF_RELEASENOTE_ROLES)
            ? [
                {
                  label: t('release-notes'),
                  icon: <FaClipboardList />,
                  onClickHandler: '/staff/release-notes',
                },
              ]
            : []),
          {
            label: t('logout'),
            icon: <FaDoorOpen />,
            onClickHandler: () => {
              logout();
            },
          },
        ],
      },
    ];
  }, [
    hasAnyOfRoles,
    activeSite,
    availableSites,
    logout,
    setActiveSiteById,
    t,
    sangixUser,
  ]);
  return (
    <DefaultLayout
      navbar={{ dropdowns: navbarDropdowns }}
      fixedHeight={fixedHeight}
    >
      {children}
    </DefaultLayout>
  );
};
