import styled from 'styled-components';
import { mq } from 'styles/sizes';
import { toRem } from 'utils/helpers';

export const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: ${toRem(1200)}rem;
  background-color: var(--white);
  padding: var(--s2) var(--s4);
  border-radius: var(--s4) var(--s4) 0 0;
  min-height: var(--s6);
  box-shadow: var(--shadow-3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--body1);
  z-index: 2;
  flex-direction: row;
`;
export const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Copyrights = styled.div``;
export const LinksWrapper = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  a {
    display: flex;
    align-items: center;
    transition: 0.2s ease color;
    svg {
      line-height: 0;
      margin-left: var(--s2);
    }
  }
  ${mq.sm} {
    flex-direction: row;
    align-items: center;
    a + a {
      margin-left: var(--s4);
    }
  }
`;
