import { useThemeMedia } from 'hooks/useThemeMedia';
import React, { ReactElement } from 'react';
import { FaClipboardList, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { mq } from 'styles/sizes';
import { Copyrights, FooterWrapper, LinksWrapper } from './Footer.styled';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

interface Props {
  hideSupport?: boolean;
}

export default React.memo(function Footer({
  hideSupport,
}: Props): ReactElement {
  const { t } = useCommonTranslation();
  const currentYear = new Date().getFullYear();
  const isDesktop = useThemeMedia(mq.sm);

  return (
    <FooterWrapper>
      <Copyrights>
        © 2008 - {currentYear} {t('sangix-appointments')}
      </Copyrights>
      {!hideSupport && isDesktop && (
        <LinksWrapper>
          <Link to="/gdpr">
            {t('how-use-data')} <FaClipboardList />
          </Link>
          <Link to="/support">
            {t('support')} <FaEnvelope />
          </Link>
        </LinksWrapper>
      )}
    </FooterWrapper>
  );
});
