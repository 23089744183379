import { FaGlobeAmericas, FaSpinner } from 'react-icons/fa';
import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const PatientNavbarLanguageIcon = styled(FaGlobeAmericas)<{
  readonly $loading?: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      animation: ${spin} infinite 2s linear;
    `}
`;

export const PatientMobileNavbarLanguageLoadingIcon = styled(FaSpinner)`
  animation: ${spin} infinite 2s linear;
`;
