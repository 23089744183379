import {
  Graphics404,
  Graphics500,
  PatientGraphics,
  PatientInner,
  PatientLayoutWrapper,
} from 'components/common';
import Footer from 'components/Footer';
import { useAuth } from 'context/AuthContext';
import { useClientRect } from 'hooks/getBoundingClientRect';
import { useSiteStringAttributes } from 'hooks/useAttribute';
import React, { ReactElement } from 'react';
import { PatientNavBar } from './patient/PatientNavBar';
import {
  COLOR_PALETTE,
  ENABLE_BACKGROUND_GRAPHICS,
} from '../configs/siteAndTrustAttributes';

interface Props {
  children: React.ReactNode;
  graphics?: 'patient' | '500' | '404';
  hideSupport?: boolean;
}

export const PatientLayoutAuth = ({
  graphics,
  children,
  hideSupport,
}: Props): ReactElement => {
  const { rect, ref } = useClientRect();
  const { sangixUser } = useAuth();

  const [colorScheme, siteBackgroundGraphics] = useSiteStringAttributes(
    COLOR_PALETTE,
    ENABLE_BACKGROUND_GRAPHICS,
  );
  const userBackgroundGraphics = sangixUser?.background_graphics;
  const showBackgroundGraphics =
    (colorScheme === '--greentercial' || !Boolean(colorScheme)) &&
    (!Boolean(siteBackgroundGraphics) || siteBackgroundGraphics !== 'false') &&
    (!Boolean(userBackgroundGraphics) || userBackgroundGraphics !== false);

  return (
    <PatientLayoutWrapper colorScheme={colorScheme}>
      {graphics === 'patient' && showBackgroundGraphics && ref.current && (
        <PatientGraphics contentRect={rect} />
      )}
      {graphics === '500' && ref.current && <Graphics500 contentRect={rect} />}
      {graphics === '404' && ref.current && <Graphics404 contentRect={rect} />}
      {graphics === 'patient' && <PatientNavBar title={false} />}
      <PatientInner authorized ref={ref}>
        {children}
      </PatientInner>
      <Footer hideSupport={hideSupport} />
    </PatientLayoutWrapper>
  );
};
