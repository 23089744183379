import { LayoutWrapper } from 'components/common';
import { Navbar, NavbarProps } from 'components/Navbar/NavBar';
import React, { useMemo } from 'react';

interface Props {
  children: React.ReactNode;
  navbar?: Pick<NavbarProps, 'logo' | 'title' | 'dropdowns'>;
  noReleaseNotes?: boolean;
  fixedHeight?: boolean;
}

export const DefaultLayout = ({
  children,
  noReleaseNotes,
  fixedHeight,
  navbar,
}: Props) => {
  const releaseNote:
    | {
        to: string;
        key: 'PATIENT' | 'STAFF';
      }
    | undefined = useMemo(
    () =>
      noReleaseNotes
        ? undefined
        : {
            key: 'STAFF',
            to: '/staff/release-notes',
          },
    [noReleaseNotes],
  );
  return (
    <LayoutWrapper fixedHeight={fixedHeight}>
      <Navbar {...navbar} releaseNote={releaseNote} />
      {children}
    </LayoutWrapper>
  );
};
