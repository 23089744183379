import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/partials/animations';
import { mq } from 'styles/sizes';
import { stripButton } from 'styles/Typography';
import { toRem, toRemStr } from 'utils/helpers';

export const DropdownWrapper = styled.div<{
  readonly autoWidth?: boolean;
}>`
  position: relative;
  display: inline-flex;
  margin-left: var(--s2);
  flex-direction: column;
  top: 0;
  ${mq.sm} {
    min-width: ${({ autoWidth }) => (autoWidth ? 'auto' : toRemStr(160))};
  }
  &:first-of-type {
    margin-left: 0;
  }
`;
export const IconWrapper = styled.div<{
  readonly isActive?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--s4);
  width: var(--s4);
  border-radius: 50%;
  font-size: 100rem;
  transition: 0.2s ease background, 0.2s ease color;
  background: #fff;
  overflow: hidden;
  margin-left: ${({ isActive }) => (isActive ? 'var(--s2)' : 0)};
  ${mq.sm} {
    margin-left: var(--s2);
  }
`;
export const DropdownBtn = styled.button<{
  readonly isActive?: boolean;
  readonly accentColor?: string;
  readonly secondaryColor?: string;
  readonly hasIcon?: boolean;
  readonly autoWidth?: boolean;
}>`
  ${stripButton};
  height: var(--s4);
  border-radius: var(--s3);
  min-width: unset;
  padding-left: ${({ isActive }) => (isActive ? 'var(--s3)' : 0)};
  padding-right: ${({ hasIcon }) => (hasIcon ? 0 : 'var(--s3)')};
  cursor: pointer;
  font-size: var(--bodysm);
  background: ${({ secondaryColor }) => secondaryColor};
  border: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${({ accentColor }) => accentColor};
  position: relative;
  z-index: 1;
  transition: 0.2s ease background, 0.2s ease color;
  ${IconWrapper} {
    color: ${({ accentColor }) => accentColor};
  }
  ${({ isActive, accentColor }) =>
    isActive &&
    css`
      box-shadow: var(--shadow-3);
      background: ${accentColor};
      color: #fff;
      ${IconWrapper} {
        background: ${accentColor};
        color: #fff;
      }
    `}

  ${mq.sm} {
    min-width: ${({ autoWidth }) => (autoWidth ? 'auto' : toRemStr(160))};
    padding-left: var(--s3);
  }
`;
export const DropdownMenu = styled.ul`
  position: relative;
  top: calc(-1 * var(--s3));
  left: 0;
  padding: ${toRem(24)}rem 0 var(--s2);
  background: #fff;
  box-shadow: var(--shadow-3);
  border-radius: 0 0 var(--s2) var(--s2);
  animation: ${fadeIn} 0.2s ease forwards;
`;
export const ListItem = styled.li``;
export const DropdownLink = styled(Link)`
  ${stripButton};
  white-space: nowrap;
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--s1) var(--s2);
  background: #fff;
  transition: 0.2s ease color, 0.2s ease background;
  color: var(--textsecondary);
  &:hover {
    color: var(--yellowaction);
    text-decoration: none;
  }
`;
export const DropdownItemIcon = styled.div`
  font-size: var(--body1);
  margin-right: var(--s2);
  line-height: 0;
`;
