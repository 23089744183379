import commonEN from './en/common.json';
import commonCS from './cs/common.json';

export const resources = {
  en: {
    common: commonEN,
  },
  cs: {
    common: commonCS,
  },
};
