import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import {
  DeleteAccountBtn,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormRow,
} from 'components/common/Forms/Forms.styled';
import CommonSelect from 'components/common/Forms/Select';
import { CardLoader } from 'components/common/Loading';
import { useAuth } from 'context/AuthContext';
import React, { ReactElement, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { patientURL } from 'routes/AppRoutes';
import { getUserProfile, updateUserProfile } from 'services/userProfile';
import { UserProfileRegisterUpdateFormValues } from 'types/users';
import { toRem } from 'utils/helpers';
import { errorToast } from 'utils/toast';
import { canChangePassword, getUserFormData } from 'utils/user';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { languagesOptions } from '../../../../i18n';
import { LABEL_BEFORE } from '../../../../styles/fontsStyleUtils';
import { useEffectOnce, useLocalStorage } from 'react-use';
import { useI18N } from '../../../../context/I18NContext';

type Props = {
  setIsFormSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFormDeleteAccount: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ProfileSettingsForm({
  setIsFormSuccess,
  setIsFormDeleteAccount,
}: Props): ReactElement {
  const { t } = useCommonTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { sangixUser, updateAuthUser } = useAuth();
  const { language } = useI18N();
  const [languageChanged, setLanguageChanged, removeLanguageChanged] =
    useLocalStorage('language-changed', false);
  const existingSangixUser = sangixUser!;
  const { ...rest } = existingSangixUser;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserProfileRegisterUpdateFormValues>({
    mode: 'onChange',
    defaultValues: {
      ...rest,
      language: existingSangixUser.language || language,
    },
  });

  const submitProfileData: SubmitHandler<
    UserProfileRegisterUpdateFormValues
  > = async (data) => {
    const { userInfo } = getUserFormData(data);
    setIsLoading(true);
    try {
      await updateUserProfile({ ...userInfo, email: data.email });
      const { data: profileData } = await getUserProfile();
      updateAuthUser({
        sangixUser: profileData,
      });
      if (data.language !== language) {
        setLanguageChanged(true);
      }
      setIsFormSuccess(true);
    } catch (err) {
      errorToast(err);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * This is workaround for showing success message after language change,
   * because language change triggers page reload. Without this, user will
   * see the success message only for a moment. Therefore, we use localStorage
   * to store the information about language change to keep it after page reload.
   */
  useEffectOnce(() => {
    if (languageChanged) {
      removeLanguageChanged();
      setIsFormSuccess(true);
    }
  });

  return (
    <DialogFormWrapper style={{ paddingTop: 'var(--s2)' }}>
      <FormBody onSubmit={handleSubmit(submitProfileData)}>
        <FormRow>
          <Controller
            name="language"
            render={({ field }) => (
              <CommonSelect
                {...field}
                required
                label={t('language')}
                labelPosition={LABEL_BEFORE}
                options={languagesOptions}
              />
            )}
            control={control}
            rules={{
              required: t('must-not-empty') as string,
            }}
          />
        </FormRow>
        <FormRow>
          <DeleteAccountBtn
            style={{
              marginLeft: 'auto',
            }}
            type="button"
            onClick={() => setIsFormDeleteAccount(true)}
          >
            {t('delete-my-account')}
          </DeleteAccountBtn>
        </FormRow>

        <FormActionsStyle>
          <CommonButton
            variant="primary"
            type="submit"
            disabled={Boolean(Object.keys(errors).length)}
            style={{ minWidth: `${toRem(160)}rem` }}
          >
            {t('save-changes')}
          </CommonButton>
          {canChangePassword(sangixUser) && (
            <CommonLink
              type="button"
              variant="danger"
              to={`${patientURL}/change-password`}
              style={{ minWidth: `${toRem(160)}rem` }}
            >
              {t('change-password')}
            </CommonLink>
          )}
          <CommonLink
            type="button"
            variant="secondary"
            style={{ minWidth: `${toRem(160)}rem` }}
            to={patientURL}
          >
            {t('back-to-menu')}
          </CommonLink>
        </FormActionsStyle>
      </FormBody>
      {isLoading && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
}
