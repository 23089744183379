import {
  PatientInner,
  PatientLayoutWrapper,
  UnAuthGraphics,
} from 'components/common';
import Footer from 'components/Footer';
import React, { ReactElement } from 'react';
import { PatientLayoutAuth } from './PatientLayoutAuth';
import { useClientRect } from '../hooks/getBoundingClientRect';
import { PatientNavBar } from './patient/PatientNavBar';

interface Props {
  children: React.ReactNode;
  graphics?: 'unauth' | 'patient' | '500' | '404';
  hideSupport?: boolean;
}

export const PatientLayout = ({
  graphics,
  children,
  ...rest
}: Props): ReactElement => {
  const { rect, ref } = useClientRect();
  return graphics === 'unauth' ? (
    <PatientLayoutWrapper>
      {ref.current && <UnAuthGraphics contentRect={rect} />}
      <PatientNavBar title={false} />
      <PatientInner ref={ref}>{children}</PatientInner>
      <Footer />
    </PatientLayoutWrapper>
  ) : (
    <PatientLayoutAuth graphics={graphics} children={children} {...rest} />
  );
};
