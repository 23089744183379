import { Loading } from 'components/common/Loading';
import { setSiteHeaders } from 'configs/Axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getActiveTrustSite, getSiteById } from 'services/sites';
import { ISiteContext, SetActiveSiteOptions, SiteData } from 'types/sites';
import { errorToast } from 'utils/toast';
import { useAuth } from './AuthContext';
import { TrustData } from '../types/trusts';
import { useUpdateUserActiveSiteMutation } from '../query/users';
import { useQueryClient } from 'react-query';
import { useHandleSiteTranslationResource } from '../hooks/i18n/useHandleSiteTranslationResource';

const defaultContext: ISiteContext = {
  allSites: undefined,
  activeSite: {} as SiteData,
  activeTrust: {} as TrustData,
  setActiveSite: undefined as any,
  setActiveSiteById: undefined as any,
};

export const SiteContext = React.createContext<ISiteContext>(defaultContext);
SiteContext.displayName = 'Site Context'; // Only for debugging

export const useSite = () => useContext(SiteContext);

export const SiteProvider: React.FC = ({ children }) => {
  const { sangixUser, updateAuthUser, logout } = useAuth();
  const [allSites, setAllSites] = useState<SiteData[]>();
  const [activeSite, setActiveSiteState] = useState<SiteData>();
  const [activeTrust, setActiveTrust] = useState<TrustData>();
  const [isFetching, setIsFetching] = useState(false);
  const { mutateAsync: updateUserActiveSite } =
    useUpdateUserActiveSiteMutation();
  const queryClient = useQueryClient();

  const setActiveSite = useCallback(
    async (
      site: SiteData,
      { save = true, showLoader = true }: SetActiveSiteOptions = {},
    ): Promise<void> => {
      if (showLoader) setIsFetching(true);
      try {
        if (save) {
          await updateUserActiveSite(site.id);
          updateAuthUser({
            sangixUser: {
              ...sangixUser!!,
              active_site_id: site.id,
            },
          });
        }
        setActiveSiteState(site);
        /**
         * Changing active site may cause a different response from BE on endpoints with same params. Therefore, we want to reload all queries.
         */
        if (save) queryClient.invalidateQueries();
      } catch (err) {
        if (save) {
          const originalSiteId = sangixUser!.active_site_id;
          updateAuthUser({
            sangixUser: {
              ...sangixUser!!,
              active_site_id: originalSiteId,
            },
          });
        }
      } finally {
        setIsFetching(false);
      }
    },
    [
      queryClient,
      setIsFetching,
      setActiveSiteState,
      updateAuthUser,
      sangixUser,
      updateUserActiveSite,
    ],
  );

  useHandleSiteTranslationResource(activeSite);

  const setActiveSiteById = useCallback(
    async (siteId: string) => {
      let site = allSites?.find((site) => site.id === siteId);
      try {
        if (!site) {
          const siteResponse = await getSiteById(siteId);
          queryClient.removeQueries();
          site = siteResponse.data;
        }
        await setActiveSite(site);
        return site;
      } catch (err) {
        errorToast(err);
      }
    },
    [allSites, queryClient, setActiveSite],
  );

  useEffect(() => {
    (async () => {
      if (sangixUser) {
        try {
          const { data } = await getActiveTrustSite();
          let activeSite = data.data?.find(
            (site) => site.id === sangixUser?.active_site_id,
          );
          if (!activeSite) {
            console.error("User's site is not found");
            activeSite = data.data?.[0];
          }
          setSiteHeaders(activeSite?.id);
          setAllSites(data.data);
          setActiveSiteState(activeSite);
          setActiveTrust(data.trust);
        } catch (err) {
          errorToast(err);
          logout();
        }
      } else {
        setActiveSiteState(undefined);
      }
    })();
  }, [logout, sangixUser]);

  useEffect(() => {
    if (activeSite) {
      setSiteHeaders(activeSite.id);
    }
  }, [activeSite]);

  if (sangixUser && !allSites && !activeSite) {
    return <Loading />;
  }

  if (Boolean(sangixUser) && !Boolean(activeSite)) {
    return <Loading />;
  }

  return (
    <SiteContext.Provider
      value={{
        activeSite: activeSite as SiteData,
        activeTrust: activeTrust as TrustData,
        setActiveSite,
        allSites,
        setActiveSiteById,
      }}
    >
      {isFetching && <Loading />}
      {children}
    </SiteContext.Provider>
  );
};
