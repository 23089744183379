import { RunningText } from 'components/common/RunningText';
import {
  SangixLogoWrapper,
  StyledSangixLogo,
} from 'components/Navbar/Navbar.styled';
import { useSiteStringAttributes } from 'hooks/useAttribute';
import { usePublicSite } from 'hooks/public/usePublicSite';
import { LCDHeaderStyled, LCDRunningTextBox } from './LCD.styled';
import { LCD_RUNNING_TEXT } from '../../../configs/siteAndTrustAttributes';
import { toRemStr } from '../../../utils/helpers';

export const LCDHeader = () => {
  const { activeSite } = usePublicSite();
  const [runningText = ''] = useSiteStringAttributes(LCD_RUNNING_TEXT);

  return (
    <LCDHeaderStyled>
      <SangixLogoWrapper
        style={{
          fontSize: 'var(--h2)',
          padding: '0 var(--s4)',
        }}
      >
        <StyledSangixLogo
          style={{ marginRight: 'var(--s4)', maxHeight: toRemStr(60) }}
        />
        {activeSite.name}
      </SangixLogoWrapper>
      <LCDRunningTextBox>
        <RunningText text={runningText} />
      </LCDRunningTextBox>
    </LCDHeaderStyled>
  );
};
